'use client';

import '@americastestkitchen/ui/styles.css';
import '@americastestkitchen/mise/styles.css';
import 'mise/src/styles/styles.scss';
import { ErrorScreen } from './_components/ErrorScreen';

// Error boundaries must be Client Components

export default function NotFound() {
  return (
    // global-error must include html and body tags
    <html>
      <body className="atk">
        <ErrorScreen errorCode={404} />
      </body>
    </html>
  );
}
